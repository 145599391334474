.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  position: relative;
  width: 100%;
  text-align: left;
  height: 45px;
  font-size: 24px;
  line-height: 45px;
  color: #fff;
  font-family: 'Rubik', cursive;

  @media (max-width: 800px) {

    font-size: 16px;
  }
}

.preTxt {
  position: absolute;
  top: 0;
  right: 47%;
  height: 45px;
}

.changeHidden {
  position: absolute;
  top: 0;

  left: 54%;
  height: 45px;
  overflow: hidden;
}

.contenant {
  position: relative;
  animation: carousel 12s ease-in-out infinite;
}

.element {

  display: block;
  color: #fca311;
}


@keyframes carousel {

  0%,
  20% {
    transform: translateY(0);
  }

  25%,
  45% {
    transform: translateY(-45px);
  }

  50%,
  70% {
    transform: translateY(-90px);
  }

  75%,
  95% {
    transform: translateY(-135px);
  }

  100% {
    transform: translateY(-180px);
  }
}