.img {
    width: 15%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
    cursor: pointer;
}

.img-small {
    width: 50%;
    height: 100%;
    transition: 0.5s;
    cursor: pointer;
}

.exp-small {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 100px;
}

.exp {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    padding-bottom: 100px;
}



.exp .img:hover {
    transform: scale(1.05);
}

.bg-img {
    height: 100vh;
}