.cache {
    width: 100px;
    height: 100px;
    background-color: #14213d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.metamask {
    cursor: pointer;
    animation: appear linear 5s;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
