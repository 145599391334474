.center-page-col {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.text-circle-sm {
    width: 150%;
}

.text-circle {
    width: 300%;
}

.center-small {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.sun {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: #fca311;
    border-radius: 50%;
    box-shadow:
        0 0 0 20px #fca311,
        0 0 0 40px #fca30040,
        0 0 0 60px #fca30020,
        0 0 0 80px #fca30010,
        0 0 0 100px #fca30000,
        0 0 40px 100px #fca30010;
    animation:
        sunrise 2s infinite linear forwards,
        rays 2s 2s infinite linear;
}

@keyframes sunrise {
    0% {
        box-shadow: none;
    }
}

@keyframes rays {
    0% {
        box-shadow:
            0 0 0 0 #fca30080,
            0 0 0 20px #fca30080,
            0 0 0 40px #fca30040,
            0 0 0 60px #fca30020,
            0 0 0 80px #fca30010,
            0 0 40px 100px #fca30010;
    }

    100% {
        box-shadow:
            0 0 0 20px #fca30080,
            0 0 0 40px #fca30040,
            0 0 0 60px #fca30020,
            0 0 0 80px #fca30010,
            0 0 0 100px #fca30000,
            0 0 40px 100px #fca30010;
    }
}