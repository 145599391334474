.flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  cursor: pointer;
  position: relative;

  h1,
  img,
  p {
    transition: 0.2s ease;
    color: #e5e5e5;
  }

  @media (max-width: 800px) {
    gap: 10px;

    h1 {
      margin: 0;
      font-size: 40px;
    }
  }
}


.flex-row-between {
  display: flex;
  justify-content: space-between;
}

.flex-row:hover {

  h1,
  img,
  p {
    transform: scale(1.05);
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  transition: .5s ease;
  background-color: #14213d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay:hover {
  opacity: 0;
}