@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:wght@700&family=Raleway:wght@400;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap');

@font-face {
  font-family: Woodland;
  src: url("./assets/fonts/ppwoodland-bold.otf") format("opentype");
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #14213d;
}

p {
  font-family: 'Rubik', cursive;
  font-weight: 400;
  margin: 0.2rem;
  font-size: 1.5rem;
  color: #e5e5e5;

  @media (max-width: 800px) {
    font-size: 1rem;
  }
}

div p {
  font-family: 'Rubik', cursive;
  font-weight: 500;
}


h1 {
  font-family: 'Woodland', cursive;
  font-size: 5rem;
  color: #fff;

  @media (max-width: 800px) {
    font-size: 3.5rem;
  }
}

h2 {
  font-family: 'Woodland', cursive;
  font-size: 3rem;
  color: #e5e5e5;
}

h3 {
  font-family: 'Woodland', cursive;
  font-size: 2rem;
  color: #e5e5e5;
}



.center-simple {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.center-simple-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}


.separator {
  padding-top: 300px;
}

.container {
  overflow-x: hidden;
}


.neonText {
  text-shadow:
    0 0 7px #fca311,
    0 0 10px #fca311,
    0 0 21px #fca311,
}

.animate__animated.animate__slideInUp {
  --animate-duration: 2s;
}