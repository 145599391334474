@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slideout {
  from {
    margin-left: 0%;
    width: 100%;
  }

  to {
    margin-left: 100%;
    width: 300%;
  }
}

.title-fixed {
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  z-index: -10;
  color: black;
  opacity: 0.2;
  font-size: 200px;
  width: 100%;
  white-space: nowrap;

  @media (max-width: 1100px) {
    font-size: 90px;
  }
}

.title-fixed-out {
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  z-index: -10;
  color: black;
  opacity: 0.2;
  font-size: 250px;
  width: 100%;
  transition: 2s;
  animation: slideout 2s infinite ease-in;

  @media (max-width: 800px) {
    font-size: 90px;
  }
}